<template>
	<div>
        <Dialog header="Mi Perfil" v-model:visible="displayPerfil" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <div class="card" v-if="perfil">
				<br>
                <h5>Datos Personales</h5>	
                <div class="p-fluid p-formgrid p-grid">        
                    <div class="p-field p-col-12 p-md-2">
                    <label>N° Cédula</label>
					<InputText v-model="perfil.id" :disabled="true"></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-5">
                    <label>Apellidos</label>
					<InputText v-model="perfil.apellido" :disabled="true"></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-5">
                    <label>Nombres</label>
					<InputText v-model="perfil.nombre" :disabled="true"></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                    <label>Correo</label>
					<InputText v-model="perfil.correo" autofocus></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                    <label>Fec. Nacimiento</label>
                    <InputText v-model="perfil.fecha_nac" type="date"/>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                    <label>Telf Habitación</label>
                    <InputMask v-model="perfil.tlf_local" mask="(9999) 999-9999" />
                    </div>                      
                    <div class="p-field p-col-12 p-md-4">
                    <label>Telf Celular</label>
                    <InputMask v-model="perfil.tlf_celular" mask="(9999) 999-9999" />
                    </div>
                </div>	

                <h5>Dirección de Habitación</h5>			
                <div class="p-fluid p-formgrid p-grid">        
                    <div class="p-field p-col-12 p-md-6">
                    <label>Estado</label>
					<Dropdown v-model="perfil.estado" :options="estados" optionLabel="nombre" placeholder="Selecciona..." @change="Validacion1()"></Dropdown>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                    <label>Ciudad</label>
					<Dropdown v-model="perfil.ciudad" :options="ciudades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                    <label>Municipio</label>
					<Dropdown v-model="perfil.municipio" :options="municipios" optionLabel="nombre" placeholder="Selecciona..." @change="Validacion2()" ></Dropdown>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                    <label>Parroquia</label>
					<Dropdown v-model="perfil.parroquia" :options="parroquias" optionLabel="nombre" placeholder="Selecciona..." v-if="municipios"></Dropdown>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                    <label>Dirección</label>
                    <Textarea v-model="perfil.direccion"  rows="4"/>						
                    </div>
                </div>	
                <h5>Datos de Empresa donde Labora</h5>			
                <div class="p-fluid p-formgrid p-grid">        
                    <div class="p-field p-col-12 p-md-6">
                    <label>Nombre de la Empresa</label>
					<InputText v-model="perfil.empresa"></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                    <label>Cargo Actual Empresa</label>
					<InputText v-model="perfil.emp_cargo"></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                    <label>Telefono</label>
                    <InputMask v-model="perfil.emp_tlf" mask="(9999) 999-9999" />
                    </div>                      
                    <div class="p-field p-col-12 p-md-5">
                    <label>Correo</label>
					<InputText v-model="perfil.emp_correo"></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                    <label>Página WEB de la Empresa</label>
					<InputText v-model="perfil.emp_web"></InputText>						
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                    <label>Dirección</label>
                    <Textarea v-model="perfil.emp_direccion"  rows="4"/>						
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <FileUpload chooseLabel="Subir Foto JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="anexos+'/foto'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader" :auto="true"/>
                    </div>
                </div>	
			</div>	
            <template #footer>
                <Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Save"/>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
   
</template>

<script>
import API from "../service/API";
import Token from "uuid-token-generator";
const Consulta = new API('Seguridad','MiPerfil');

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
			displayPerfil: false,
            perfil: null,
            size: '60vw',
            visibleFull: false,
            estados: null,
            ciudades: [],
            municipios: [],
            parroquias: [],
			url: null,
			token: null,
            anexos: null,
		}
	},
    created() {   
        this.anexos = process.env.VUE_APP_ANEXOS;   
        //this.url = process.env.VUE_APP_API;
        if (this.$store.state.mobile){this.size = '80vw';}
        const tokgen = new Token();
        this.token = tokgen.generate()+'_'+this.$store.state.id;
		this.$store.commit('Loading');
        this.Mostrar();
    },
    methods: {
		myUploader() {
            this.$store.state.foto = this.token;
            /*
			Consulta.Procesar('Foto',{
				foto: this.$store.state.foto,
				token: this.token,
			}).then(response => {
				if (response.result) {
					this.$store.state.foto = this.token;
				}
				this.$store.commit('Loading');
			});
            */
        },
        Mostrar() {
			Consulta.Ini().then(result => {
                this.$store.state.error = result;	
                /*
                this.estados = result.consult.estado;
                this.ciudades = result.consult.ciudad;
                this.municipios = result.consult.municipio;
                this.parroquias = result.consult.parroquia;
				this.perfil = result.consult.perfil;
                if(this.perfil.estado){
                    this.perfil.estado = this.estados.filter(val => val.code == this.perfil.estado)[0];
                }
                if(this.perfil.ciudad){
                    this.perfil.ciudad = this.ciudades.filter(val => val.code == this.perfil.ciudad)[0];
                }
                if(this.perfil.municipio){
                    this.perfil.municipio = this.municipios.filter(val => val.code == this.perfil.municipio)[0];
                }
                if(this.perfil.parroquia){
                    this.perfil.parroquia = this.parroquias.filter(val => val.code == this.perfil.parroquia)[0];
                }
                    */
			}); 
            
        },
        Validacion1() {
            this.parroquias = [];
            Consulta.Procesar('Validacion1',{
                estado: this.perfil.estado.code,
            }).then(response => {
                this.$store.commit('Loading');
                //this.$store.state.error = response;	
                this.ciudades = response.result.ciudad;
                this.municipios = response.result.municipio;
            });
        },
        Validacion2() {
            Consulta.Procesar('Validacion2',{
                municipio: this.perfil.municipio.code,
            }).then(response => {
                this.$store.commit('Loading');
                this.parroquias = response.result.parroquia;
            });
        },
        close() {
            this.displayPerfil = false;
            this.$emit('close-perfil', event);
        },
        Save() {
            if (this.perfil.correo == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.perfil.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
			} else if (this.perfil.fecha_nac == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Fecha de Nacimiento', life: 5000});
			} else if (this.perfil.tlf_local == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Tlf Habitación', life: 5000});
			} else if (this.perfil.tlf_celular == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nº Celular', life: 5000});
			} else if (this.perfil.estado == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Estado', life: 5000});
			} else if (this.perfil.ciudad == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Ciudad', life: 5000});
			} else if (this.perfil.municipio == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Municipio', life: 5000});
			} else if (this.perfil.parroquia == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Parroquia', life: 5000});
			} else if (this.perfil.direccion == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Dirección', life: 5000});
            } else {
                Consulta.Procesar('UPD',{
                    correo: this.perfil.correo,
                    fecha_nac: this.perfil.fecha_nac,
                    tlf_local: this.perfil.tlf_local,
                    tlf_celular: this.perfil.tlf_celular,
                    estado: this.perfil.estado.code,
                    ciudad: this.perfil.ciudad.code,
                    municipio: this.perfil.municipio.code,
                    parroquia: this.perfil.parroquia.code,
                    direccion: this.perfil.direccion,
                    empresa: this.perfil.empresa,
                    emp_direccion: this.perfil.emp_direccion,
                    emp_tlf: this.perfil.emp_tlf,
                    emp_correo: this.perfil.emp_correo,
                    emp_web: this.perfil.emp_web,
                    emp_cargo: this.perfil.emp_cargo
                }).then(response => {
                    //this.$store.state.error = response;	
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.close();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                    this.$store.commit('Loading');
                });
            } 
        },
        onMenuToggle(event) {
            this.$emit('close-perfil', event);
        },	
	},
    watch: {
		display(newValue) {
			this.displayPerfil = newValue;
		}
	},


}
</script>